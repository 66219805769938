import React from "react"
import { graphql } from "gatsby"
import SEO from "components/seo"
import Layout from "components/layout"
import Markdown from "components/markdown"

const AboutPage = ({ data }) => {
  const {
    body: {
      childMarkdownRemark: { html },
    },
    title,
  } = data.contentfulPage

  return (
    <Layout>
      <SEO title="About Me" />
      <h1>{title}</h1>
      <Markdown html={html} />
    </Layout>
  )
}

export const aboutPageQuery = graphql`
  query aboutPageQuery {
    contentfulPage(contentful_id: { eq: "4ZVztzhD7qQJwt9ygEJl9k" }) {
      body {
        childMarkdownRemark {
          html
        }
      }
      title
    }
  }
`

export default AboutPage
